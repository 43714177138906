import React from "react"
import Info2column from "../../../components/Info2column"
import QuestionAndAnswer2colmun from "../../../components/QuestionAndAnswer2colmun"
import CommentText from "../../../components/CommentText"
import ColumnGrid from "../../../components/ColumnGrid"
import CommonButton from "../../../components/CommonButton"
import ModalLink from "../../../components/ModalLink"
import LayoutInterview from "../../../components/LayoutInterview"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Seo from "../../../components/Seo"

import IconInfo from "../../../images/icon_info.svg"
import IconPhoto from "../../../images/icon_photo.svg"
import QuestionAndAnswerToggle from "../../../components/QuestionAndAnswerToggle"
import FlagId from "../../../images/fkag_id.svg"

import FaceImg01 from "../../../images/interview/03/face_01.jpg"
import FaceImg02 from "../../../images/interview/03/face_02.jpg"
import css from "../stylesheets/interview.module.scss"
import { graphql } from "gatsby"
import Img from "gatsby-image"

export default ({ data }) => {
  return (
    <div className={css.InterviewDetail}>
      <Seo title={`働く場所で、子どもも育つ | INTERVIEW`} />
      <LayoutInterview lang={`ja`}>
        <Info2column id={`interview-03`} noBorder={true}>
          <div>
            <Img fluid={data.image1.childImageSharp.fluid} />
          </div>
          <div>
            <p className={css.date}>{`2020.3.25`}</p>
            <h3 className={`mb20`}>働く場所で、子どもも育つ</h3>
            <p>
              リア
              <small>さん（33歳）</small>× ウチュ
              <small>さん（33歳）</small>
            </p>
            <p>
              <AnchorLink
                className={`linkUnderline`}
                to="/ja/basic-information/kaigo/#section-2"
              >
                EPA介護福祉士候補者
              </AnchorLink>
              として、インドネシアから来日したウチュさんとリアさん。しばらくは別々の施設に勤めていましたが、結婚し、2016年からは千葉県成田市にある特別養護老人ホーム『
              <ModalLink id={`facility`} className={`linkUnderline`}>
                <span style={{ color: "black" }}>
                  杜の家なりた
                  <img src={IconPhoto} alt="" />
                </span>
                <div>
                  <h3>働いている施設</h3>
                  <Img fluid={data.facility1.childImageSharp.fluid} />
                  <Img fluid={data.facility2.childImageSharp.fluid} />
                  <Img fluid={data.facility3.childImageSharp.fluid} />
                  <Img fluid={data.facility4.childImageSharp.fluid} />
                  <Img fluid={data.facility5.childImageSharp.fluid} />
                </div>
              </ModalLink>
              』で一緒に働いています。仕事と子育てを両立している2人に、日本での生活について聞きました。
            </p>
            <h3>PROFILE</h3>
            <table className={`profileTable`}>
              <tbody>
                <tr>
                  <th>名前</th>
                  <td>ウチュ　スヘンドリさん</td>
                </tr>
                <tr>
                  <th>勤務先</th>
                  <td>
                    社会福祉法人 福祉楽団 特別養護老人ホーム『杜の家なりた』
                    介護福祉士
                  </td>
                </tr>
                <tr>
                  <th>出身地</th>
                  <td>
                    <img
                      src={FlagId}
                      alt="インドネシア国旗"
                      className={`flag`}
                    />
                    インドネシア・西ジャワ州スカブミ
                  </td>
                </tr>
                <tr>
                  <th>年齢</th>
                  <td>33歳（1986年生まれ） </td>
                </tr>
                <tr>
                  <th>経歴</th>
                  <td>
                    シャリフヒダヤトゥッラージャカルタ大学
                    看護学部卒業後、病院で1年間看護師として働く
                  </td>
                </tr>
                <tr>
                  <th>
                    入国時の
                    <br />
                    在留資格
                  </th>
                  <td>EPA介護福祉士候補者</td>
                </tr>
                <tr>
                  <th>来日</th>
                  <td>2012年5月</td>
                </tr>
              </tbody>
            </table>

            <table className={`profileTable`}>
              <tbody>
                <tr>
                  <th>名前</th>
                  <td>リア　プレスティア　アングライニさん</td>
                </tr>
                <tr>
                  <th>勤務先</th>
                  <td>
                    社会福祉法人 福祉楽団 特別養護老人ホーム『杜の家なりた』
                    介護福祉士
                  </td>
                </tr>
                <tr>
                  <th>出身地</th>
                  <td>
                    <img
                      src={FlagId}
                      alt="インドネシア国旗"
                      className={`flag`}
                    />
                    インドネシア・ジャカルタ
                  </td>
                </tr>
                <tr>
                  <th>年齢</th>
                  <td>33歳（1987年生まれ）</td>
                </tr>
                <tr>
                  <th>経歴</th>
                  <td>
                    スティクスビナワン大学
                    看護学部卒業後、病院で3か月間看護師として働く
                  </td>
                </tr>
                <tr>
                  <th>
                    入国時の
                    <br />
                    在留資格
                  </th>
                  <td>EPA介護福祉士候補者</td>
                </tr>
                <tr>
                  <th>来日</th>
                  <td>2013年12月</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Info2column>
        <QuestionAndAnswerToggle
          question={`介護の仕事をやってみてどうですか？`}
        >
          <QuestionAndAnswer2colmun>
            <div>
              <Img
                fluid={data.image2.childImageSharp.fluid}
                className={`mb20`}
              />
              <Img fluid={data.image3.childImageSharp.fluid} />
            </div>

            <div>
              <CommentText
                src={FaceImg02}
                direction={`right`}
                name={`リア さん`}
              >
                <p>
                  仕事に慣れるまでは1年くらいかかりました。ごはんを食べてもらおうとしても口をあけてもらえなかったりして、大変だと感じることが多かったです。
                </p>
                <p>
                  いまは、介護は人生をケアすることだと思っています。毎日のことから看取りまで、一人ひとりを尊重しながら、その人に必要なケアをすることが大切です。
                </p>
                <p>
                  利用者さんはロボットじゃないので、口をあけてくれないときには理由があります。いまは無理をしないで、時間をかけたり、眠そうだったら、温かいおしぼりで顔をふいて、目が覚めるようにお手伝いをしたりします。
                </p>
              </CommentText>
            </div>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image4.childImageSharp.fluid} />
            </div>
            <div>
              <CommentText src={FaceImg01} name={`ウチュ さん`}>
                <p>そうですね。利用者さんに無理をさせないことは大切です。</p>
                <p>
                  介護は、食事、排泄、入浴などの人間の基本的な生活を支える仕事です。そのためには、一人ひとりをよく知ることが必要です。一人ひとりの状態は、朝礼で共有して、介護記録システムでチェックしています。
                </p>
                <p>
                  この介護記録システムは、いつでもパソコンやスマートフォンから、利用者さん一人ひとりの介護記録を管理できます。体温や血圧などのバイタルサインの記録を見ることができるので、とても便利です。重要な内容には印がついているので、必ずチェックします。SNSのようにコメントをつけて、職員同士や利用者さんの家族とやりとりもできるんですよ。
                </p>
              </CommentText>
            </div>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg02} direction={`right`} name={`リア さん`}>
              <p>
                利用者さんの好きなことや嫌いなことが記録してあって、写真やビデオを見ることもできます。言葉だと伝わりにくいことも、共有できるのがいいですね。わたしは、いつも静かな利用者さんが、ムーンウォークを見せてくれた映像を撮ったことがありますよ（笑）。
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image5.childImageSharp.fluid} />
            </div>
            <div>
              <CommentText src={FaceImg01} name={`ウチュ さん`}>
                <p>
                  利用者さんの家族は、「会えないときの様子を知れて嬉しい」と言ってくれます。
                </p>
                <p>
                  介護記録システムでは、リアさんが記入したものに「いいね」のボタンを押すこともありますよ。
                </p>
              </CommentText>
            </div>
          </QuestionAndAnswer2colmun>
        </QuestionAndAnswerToggle>

        <QuestionAndAnswerToggle question={`働いている施設の特徴は何ですか？ `}>
          <QuestionAndAnswer2colmun>
            <div>
              <Img
                fluid={data.image6.childImageSharp.fluid}
                className={`mb20`}
              />
              <Img fluid={data.image7.childImageSharp.fluid} />
            </div>

            <div>
              <CommentText
                src={FaceImg02}
                direction={`right`}
                name={`リア さん`}
              >
                <p>施設の隣に保育所があるので、娘を預けられるんですよ。</p>
              </CommentText>
              <CommentText src={FaceImg01} name={`ウチュ さん`}>
                <p>子どもたちは、施設にもよく遊びに来てくれます。</p>
                <p>
                  わたしが担当した利用者さんにも、娘のことを覚えてくれた方がいました。それまではあまり会話が多くなかったのですが、そのうち「娘さんは元気？」と聞いてくれるようになったんです。子どもの話題が、利用者さんと心を通わせるきっかけになりました。
                </p>
              </CommentText>
            </div>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image9.childImageSharp.fluid} />
            </div>
            <div>
              <CommentText
                src={FaceImg02}
                direction={`right`}
                name={`リア さん`}
              >
                <p>
                  保育所のほかにも、高齢者と子どもの
                  <ModalLink id={`modal-1`} style={{ display: `inline-block` }}>
                    <span>
                      デイサービス
                      <img src={IconInfo} alt="" />
                    </span>
                    <div>
                      <h3>デイサービス</h3>
                      <p>
                        自分の家で暮らしている高齢者や障害者が、日帰りで通う介護サービスです。食事や入浴、日常動作の訓練、レクリエーションなどを行うことができます。家から施設までは、車やバスで送ります。
                      </p>
                    </div>
                  </ModalLink>
                  が一緒にあります。月に2回オープンする美容室や、料理をしているところが見える調理室もあります。車でパン屋さんが来たり、月に1回地域の人たちを呼んで、ごはんを一緒に食べたりすることもあるんですよ。
                </p>
                <p>
                  デイサービスでは、毎日
                  <ModalLink id={`modal-2`} style={{ display: `inline-block` }}>
                    <span>
                      マージャン
                      <img src={IconInfo} alt="" />
                    </span>
                    <div>
                      <h3>マージャン</h3>
                      <p>
                        中国で生まれたテーブルゲームで、4人で勝負します。手先を使い、記憶力も必要なため、認知症の予防にも効果があるとされています。
                      </p>
                    </div>
                  </ModalLink>
                  やカラオケ、体操などのレクリエーションをやっています。施設に暮らしている利用者さんが参加するときには、トイレに連れて行ったり、身だしなみを整えたりと、準備に忙しいです（笑）。
                </p>
              </CommentText>
            </div>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image8.childImageSharp.fluid} />
            </div>
            <div>
              <CommentText src={FaceImg01} name={`ウチュ さん`}>
                <p>
                  一人ひとりにあわせたケアのためにはやることがたくさんあるのですが、障害をもっている職員やボランティアの人などと協力しながら、一緒に働いています。
                </p>
              </CommentText>
            </div>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg02} direction={`right`} name={`リア さん`}>
              <p>
                空いた時間は、ケアプランを作成するなど、一人ひとりの利用者さんと向きあうことに時間を使っていますね。
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
        </QuestionAndAnswerToggle>

        <QuestionAndAnswerToggle
          question={`ムスリムとして働く大変さはありますか？`}
        >
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image10.childImageSharp.fluid} />
            </div>
            <CommentText src={FaceImg02} direction={`right`} name={`リア さん`}>
              <p>
                働く施設を探すときに、ヒジャブ（Hijab）を被って仕事ができることを条件にしました。実際に働いてみて、特に大変だと感じることはありません。
              </p>
              <p>
                男性の介護もしますが、みなさん高齢なので自分のお父さんみたいな感じ。だから神様もわかってくれていると思います。仕事としてやっていることだから。
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg01} name={`ウチュ さん`}>
              <p>
                家から車で20分くらいの場所にモスクがあって、毎週金曜日に通っています。モスクが近くにあると、神様を近くに感じて、信頼関係が深まるように感じます。
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg02} direction={`right`} name={`リア さん`}>
              <p>
                仕事中もお祈りをします。忙しいときは、落ち着いてから「いまお祈りをしていいですか？」と同僚に声をかけています。
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image11.childImageSharp.fluid} />
            </div>

            <CommentText src={FaceImg01} name={`ウチュ さん`}>
              <p>
                いつでもお祈りをしていいのですが、タイミングは大事ですね。1日5回のお祈りをする時間帯のなかで、時間を見つけて、5分ほどお祈りをします。
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg02} direction={`right`} name={`リア さん`}>
              <p>
                日の出から日没まで
                <ruby>
                  <span className={`rb`}>断食</span>
                  <rt>だんじき</rt>
                </ruby>
                をするラマダン（Ramadan）の時期には、入浴介助は体力を使うのでやらなくていいと言われますが、自分の責任だし、大丈夫だと思って入浴介助もやっています。でも、みんな「倒れちゃうから、やらなくて大丈夫だよ」って（笑）。
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg01} name={`ウチュ さん`}>
              <p>そうそう、すごく心配してくれますね。</p>
            </CommentText>
          </QuestionAndAnswer2colmun>
        </QuestionAndAnswerToggle>

        <QuestionAndAnswerToggle
          question={`日本ではどんな生活をしていますか？`}
        >
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image12.childImageSharp.fluid} />
            </div>

            <CommentText src={FaceImg02} direction={`right`} name={`リア さん`}>
              <p>
                ウチュさんとわたしの勤務時間が違うので、保育所の送り迎えは
                <ModalLink id={`modal-3`} style={{ display: `inline-block` }}>
                  <span>
                    遅番
                    <img src={IconInfo} alt="" />
                  </span>
                  <div>
                    <h3>遅番</h3>
                    <p>
                      利用者が暮らしている施設では、24時間体制での介護が必要です。シフト体制で、1か月ごとに法律で定められた労働時間を調整しています。『杜の家なりた』では、7:15〜16:15（早番）、11:00〜20:00（遅番）、18:45〜8:45（夜勤）にわかれていて、月に約5回の夜勤があります。
                    </p>
                  </div>
                </ModalLink>
                の人がやっています。お弁当もどちらかがつくります。ウチュさんは料理ができるから助かりますね。
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>

          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg01} name={`ウチュ さん`}>
              <p>
                最近、関東地方で有名な『肉のハナマサ』などのスーパーマーケットで、ムスリムが食べられるハラルフード（Halal）を売っているんですよ。近所にあるので便利です。
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg02} direction={`right`} name={`リア さん`}>
              <p>
                生活はしやすいですね。月に2日は希望した日に休みが取れるので、そこで2人の休みをあわせています。
              </p>
              <p>
                休みの日には、娘と公園で遊んだり『イオン』などのショッピングモールに行ったりします。日本はインドネシアと違って四季があるので、桜や紅葉を見に、成田山などの観光地へ季節ごとに出かけるのも楽しいです。
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>

          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image13.childImageSharp.fluid} />
            </div>
            <CommentText src={FaceImg01} name={`ウチュ さん`}>
              <p>
                日本にいるインドネシア人には、いろいろな横のつながりがあるんですよ。
              </p>
              <p>
                たとえば、EPAで来日しているインドネシア人のコミュニティ『IPMI（Ikatan
                Perawat Muslim
                Indonesia）』や、『在日インドネシアムスリム協会（KMII）』があります。集会に参加したり、友だちと予定をあわせて、去年は山梨県に1泊2日で旅行に行きました。
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg02} direction={`right`} name={`リア さん`}>
              <p>
                全国のIPMIのイベントのときは、200人くらい集まりますよ。LINE・Facebook・Instagramで情報交換をしています。
              </p>
              <p>
                ただ、近くに両親や親戚がいないので、子どもが病気になったときなどに預ける人がいないのは不安ですね。
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>

          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg01} name={`ウチュ さん`}>
              <p>
                そうですね。でも、家族と一緒だし、仕事も安定しているのはいいよね。リアさんとは同じフロアで働いているので、仕事の相談もできます。
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image14.childImageSharp.fluid} />
            </div>
            <CommentText src={FaceImg02} direction={`right`} name={`リア さん`}>
              <p>
                お互いが担当している利用者さんを知っているので、家では仕事の話をよくするよね。楽しいことがあったら、2人で盛り上がることもありますよ（笑）。
              </p>
              <p>
                結婚して5年経ちますが、関係はあまり変わりません。ケンカすることもあるけれど、2人で解決して、いい家族でありたい。毎日が幸せなわけじゃないけど、それが家族ですね。
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
        </QuestionAndAnswerToggle>

        <QuestionAndAnswerToggle question={`これからの目標を教えてください。 `}>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg01} name={`ウチュ さん`}>
              <p>
                わたしの目標は永住ビザを取ることと、日本に家をもつことです。看護師の資格も日本で取りたいので、まずは日本語検定のN1に合格したいです。
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg02} direction={`right`} name={`リア さん`}>
              <p>
                わたしは、いつかは両親がいるインドネシアに帰りたいです。再来年にはN1をとって、通訳者になるのが夢です。インドネシアに土地をもっているので、家も建てたいですね。
              </p>
              <p>
                いまは、働いて2年目から12日以上の休暇がとれる「リフレッシュ休暇」という制度を使って、いつもインドネシアに帰っています。
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg01} name={`ウチュ さん`}>
              <p>いつか家族でメッカ（Makkah）に行く夢もあります。</p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg02} direction={`right`} name={`リア さん`}>
              <p>
                3歳の娘もお祈りの真似はしますが、イスラム教について、どのように教えていくかは課題です。娘が初めてしゃべった言葉は、日本語の「嫌だ」でした（笑）。それくらい日本での生活になじんでいるんですよ。それに、いま2人目の子どもを妊娠しています。
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image15.childImageSharp.fluid} />
            </div>
            <CommentText src={FaceImg01} name={`ウチュ さん`}>
              <p>
                日本とインドネシアの両方に家を建てたいのは、子どもたちのためでもあります。もしインドネシアに暮らすことになっても、日本に家があれば、日本の大学へ進学したりしやすいですから。選択肢を増やして、どちらの国でも暮らせる環境をつくってあげたいと思っています。
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
        </QuestionAndAnswerToggle>

        <div className={`mt30`}>
          <Info2column noBorder={true}>
            <div></div>
            <div>
              <p style={{ margin: 0 }}>働いている施設</p>
              <h3 className={`mb10`}>
                <ModalLink id={`facility`} className={`linkUnderline`}>
                  <span style={{ color: "black" }}>
                    杜の家なりた
                    <img src={IconPhoto} alt="" />
                  </span>
                  <div>
                    <h3>働いている施設</h3>
                    <Img fluid={data.facility1.childImageSharp.fluid} />
                    <Img fluid={data.facility2.childImageSharp.fluid} />
                    <Img fluid={data.facility3.childImageSharp.fluid} />
                    <Img fluid={data.facility4.childImageSharp.fluid} />
                    <Img fluid={data.facility5.childImageSharp.fluid} />
                  </div>
                </ModalLink>
              </h3>
              <p className={`mb40`}>
                特別養護老人ホーム（定員100名）、ショートステイ（定員10名）、デイサービスなどを行っています。保育所や、子ども向けのデイサービスもあり、障害者も働いているので、さまざまな人たちがともにいることができる環境があります。
              </p>
              <p>
                <a
                  href={`https://www.gakudan.org`}
                  rel={`noopener noreferrer`}
                  target={`_blank`}
                >
                  https://www.gakudan.org
                </a>
              </p>
              <div className="credit">
                <p>
                  Text by Tami Ono
                  <br />
                  Photo by Isamu Sakamoto
                </p>
              </div>
              <div className="mt40">
                <ColumnGrid>
                  <div className={css.commonButton}>
                    <CommonButton to={`/ja/interview/2`} align={`center`}>
                      PREV
                    </CommonButton>
                  </div>
                  <div className={css.commonButton}>
                    <CommonButton to={`/ja/interview/`} align={`center`}>
                      BACK
                    </CommonButton>
                  </div>
                  <div className={css.commonButton}>
                    <CommonButton to={`/ja/interview/1`} align={`center`}>
                      NEXT
                    </CommonButton>
                  </div>
                </ColumnGrid>
              </div>
            </div>
          </Info2column>
        </div>
      </LayoutInterview>
    </div>
  )
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "interview/03/01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image2: file(relativePath: { eq: "interview/03/02.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image3: file(relativePath: { eq: "interview/03/03.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image4: file(relativePath: { eq: "interview/03/04.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image5: file(relativePath: { eq: "interview/03/05.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image6: file(relativePath: { eq: "interview/03/06.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image7: file(relativePath: { eq: "interview/03/07.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image8: file(relativePath: { eq: "interview/03/08.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image9: file(relativePath: { eq: "interview/03/09.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image10: file(relativePath: { eq: "interview/03/10.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image11: file(relativePath: { eq: "interview/03/11.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image12: file(relativePath: { eq: "interview/03/12.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image13: file(relativePath: { eq: "interview/03/13.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image14: file(relativePath: { eq: "interview/03/14.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image15: file(relativePath: { eq: "interview/03/15.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    facility1: file(relativePath: { eq: "interview/03/facility_01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    facility2: file(relativePath: { eq: "interview/03/facility_02.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    facility3: file(relativePath: { eq: "interview/03/facility_03.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    facility4: file(relativePath: { eq: "interview/03/facility_04.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    facility5: file(relativePath: { eq: "interview/03/facility_05.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
